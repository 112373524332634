// TODO: Break up
[data-theme='light'] {
  body {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    scroll-behavior: smooth;
    color: #949597;
    background: #f7f9fc;
    line-height: 19.2px;
  }

  img {
    max-width: 100%;
  }

  a {
    text-decoration: none;
  }

  .text-right {
    text-align: right;
  }

  .container {
    max-width: 1170px;
  }

  .gap-24 {
    gap: 24px;
  }

  .h-full {
    height: 100%;
  }

  /* # Preloader */

  .preloader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #f1f1f1;
    transform-origin: bottom;
    z-index: 111111;

    .black_wall {
      height: 100%;
      background-color: #f5f5f5;
      transform-origin: top;
      animation: preloader_slide 0.5s ease-in-out 0s 1 normal both;
    }

    .loader {
      width: 100%;
      height: 3px;
      position: absolute;
      border-radius: 2px;
      top: 0;
      right: 0;
      left: 0;
      margin: auto;
      background-color: var(--primary_color);
      transform-origin: left;
      animation: loader_running 3s ease-in-out 1s infinite normal both;
    }

    &.off {
      animation: preloader_slide 0.5s ease-in-out 0s 1 reverse both;
    }
  }

  /* # Margin */

  .mt-24 {
    margin-top: 24px;
  }

  .mb-24 {
    margin-bottom: 24px;
  }

  ul,
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .flex-1 {
    flex: 1;
  }

  .theme-btn {
    background: var(--dark);
    color: #ffffff;
    display: inline-block;
    transition: 0.3s;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 500;
    padding: 12px 30px;

    &:hover {
      background: var(--primary_color);
      color: #ffffff;
    }
  }

  .shadow-box {
    position: relative;
    border-radius: 30px;
    background: #ffffff;

    * {
      z-index: 2;
      position: relative;
    }
  }

  .section-heading {
    color: #0f0f0f;
    font-size: 74px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: -01px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 2px;
    justify-content: center;

    img {
      display: block;
    }
  }

  /* # InfoBox */

  .info-box {
    padding: 24px;
  }

  .credential-area .credential-sidebar .overlay-link,
  .contact-area .contact-form .overlay-link {
    left: 0;
    top: 0;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    z-index: 9;
  }

  .project-details-wrap {
    .project-about-2 .overlay-link,
    .project-infos-wrap .overlay-link {
      left: 0;
      top: 0;
      border-radius: 30px;
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      z-index: 9;
    }
  }

  .projects-area .project-item .overlay-link {
    left: 0;
    top: 0;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    z-index: 9;
  }

  .about-area {
    .about-edc-exp .overlay-link,
    .about-image-box .overlay-link,
    .about-me-box .overlay-link {
      left: 0;
      top: 0;
      border-radius: 30px;
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      z-index: 9;
    }
  }

  .info-box .overlay-link {
    left: 0;
    top: 0;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    z-index: 9;
  }

  .credential-area .credential-sidebar .bg-img,
  .contact-area .contact-form .bg-img {
    opacity: 0.1;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .project-details-wrap {
    .project-about-2 .bg-img,
    .project-infos-wrap .bg-img {
      opacity: 0.1;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 30px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .projects-area .project-item .bg-img {
    opacity: 0.1;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .about-area {
    .about-edc-exp .bg-img,
    .about-image-box .bg-img,
    .about-me-box .bg-img {
      opacity: 0.1;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 30px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .info-box {
    .bg-img {
      opacity: 0.1;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 30px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    > img {
      display: block;
      margin: auto auto 16px auto;
    }

    .infos {
      h4 {
        color: #bcbcbc;
        font-size: 12px;
        margin-bottom: 6px;
        text-transform: uppercase;
        margin-top: 8px;
      }

      h1 {
        font-size: 20px;
        color: #0f0f0f;
        font-weight: 500;
      }
    }

    .about-btn {
      opacity: 0.2;
      transition: 0.3s;
      display: inline-block;
    }

    &:hover .about-btn {
      opacity: 1;
    }

    .inner-profile-icons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      padding: 22px;
      margin-bottom: 16px;
      background: #fbfbfc;

      a {
        width: 82px;
        height: 82px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: relative;
        background: #ffffff;
        color: var(--primary_color);
        transition: 0.3s;

        &:hover {
          background: #ffffff;
          color: #0f0f0f;
        }

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          z-index: -1;
          opacity: 0.1;
          background: linear-gradient(
            120deg,
            rgba(255, 255, 255, 0.5),
            rgba(255, 255, 255, 0.1)
          );
          backdrop-filter: blur(40px);
        }

        i {
          font-size: 32px;
        }
      }
    }

    &.about-services-box .icon-boxes {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 80px;
      margin-bottom: 56px;
      margin-top: 46px;

      i {
        font-size: 40px;
        color: var(--primary_color);
      }
    }

    &.about-client-box .clients .client-item {
      position: relative;
      border-radius: 30px;
      text-align: center;
      flex: 1;
      padding: 42px 30px 42px 30px;
      background: #fbfbfc;

      h1 {
        color: var(--primary_color);
        font-size: 34px;
        margin-bottom: 17px;
        line-height: 1.2;
        letter-spacing: -1px;
      }

      p {
        font-size: 12px;
        color: #bcbcbc;
        margin: 0;
        text-transform: uppercase;
      }
    }

    &.about-contact-box {
      padding-bottom: 42px;
      padding-top: 83px;

      h1 {
        font-size: 44px;
        line-height: 52px;
        font-weight: 500;
        color: #0f0f0f;
        margin: 0;

        span {
          color: var(--primary_color);
        }
      }

      .about-btn {
        position: absolute;
        display: block;
        right: 30px;
        bottom: 35px;
        opacity: 0.2;
        transition: 0.3s;
      }

      &:hover .about-btn {
        opacity: 1;
      }

      .star-icon {
        position: absolute;
        left: 30px;
        top: 0;

        /* opacity: 0.5; */
        margin: 0;
      }
    }

    &.about-profile-box,
    &.about-blog-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  /* .info-box.about-client-box .clients .client-item::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0.1;
      background: linear-gradient(120deg, rgba(255,255,255,0.3), rgba(255,255,255,0.05));
      border-radius: 30px;
  } */

  /* # Breadcrumb */

  .breadcrumb-area {
    padding-top: 77px;

    .breadcrumb-content {
      p {
        color: #bcbcbc;
        margin-bottom: 24px;
      }

      .section-heading {
        font-size: 56px;
        position: relative;
        display: block;
        margin-bottom: 30px;

        img {
          display: initial;
        }
      }
    }
  }

  /* # Header */

  .header-area {
    margin-top: 10px;

    .logo {
      display: inline-block;
      z-index: 16;

      img {
        display: block;
      }
    }

    .navbar {
      margin: 0;
      padding: 0;

      .theme-btn {
        display: none;
      }

      .menu {
        display: flex;
        align-items: center;
        gap: 49px;
        margin: 0;
        padding: 0;

        li {
          a {
            font-size: 16px;
            color: #676767;
            transition: 0.3s;
            display: block;
            padding: 26px 0px;
          }

          &.active a,
          a:hover {
            color: var(--primary_color);
          }
        }
      }
    }

    .show-menu {
      display: none;
      color: #0f0f0f;
      font-size: 25px;
      cursor: pointer;
      width: 25px;
      height: 18px;
      flex-direction: column;
      justify-content: space-between;
      z-index: 16;

      span {
        width: 100%;
        height: 1px;
        background: #0f0f0f;
        display: block;
        transition: 0.4s;
      }

      &.active span:first-child {
        transform: translate3d(0px, 6px, 0px) scale(1, 1) rotate(45deg)
          scale(1, 1);
      }

      span:last-child {
        transform-origin: left;
      }

      &.active span {
        &:last-child {
          transform: translate3d(4px, -2px, 0px) scale(1, 1) rotate(-45deg)
            scale(1, 1);
        }

        &:nth-child(2) {
          transform: scale(0);
        }
      }
    }
  }

  /* # About */

  .about-area {
    padding-top: 80px;

    .about-me-box {
      position: relative;
      padding: 50px 46px;
      display: flex;
      align-items: flex-start;
      gap: 30px;

      .img-box {
        width: 224px;
        height: 224px;
        position: relative;
        overflow: hidden;
        background: linear-gradient(
          90deg,
          #3c58e3 -15%,
          #c2ebff 58%,
          #5ab5e2 97%
        );
        border-radius: 30px 0 30px 0;
        flex: none;

        img {
          display: block;
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      .infos {
        padding-top: 30px;
        position: initial;

        h4 {
          color: #bcbcbc;
          opacity: 0.7;
          font-size: 14px;
          letter-spacing: 0.3px;
          margin-bottom: 3px;
        }

        h1 {
          font-size: 36px;
          line-height: 40px;
          color: #0f0f0f;
          font-weight: 500;
          margin-bottom: 12px;
        }

        p {
          letter-spacing: 0.3px;
          margin: 0;
        }

        .about-btn {
          display: block;
          position: absolute;
          right: 30px;
          bottom: 30px;
          opacity: 0.2;
          transition: 0.3s;
        }
      }

      &:hover .infos .about-btn {
        opacity: 1;
      }
    }

    .about-credentials-wrap .banner {
      padding: 17px 25px;
      margin-bottom: 24px;
    }
  }

  .marquee {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 19px;
    overflow-x: hidden;

    div {
      position: absolute;
      white-space: nowrap;
      will-change: transform;
      font-size: 12px;
      animation: marquee 8s linear infinite;
    }
  }

  .about-area {
    .about-credentials-wrap {
      .banner .marquee {
        b {
          color: var(--primary_color);
          font-weight: 400;
        }

        img {
          display: inline-block;
          margin: 0 5px;
        }
      }

      > .gx-row > * {
        flex: 1;
        width: 50%;
      }
    }

    .about-image-box {
      width: 370px;
      flex: none;
      padding: 24px;
      border-radius: 30px;

      .image-inner {
        background: linear-gradient(
          90deg,
          #3c58e3 -15%,
          #c2ebff 58%,
          #5ab5e2 97%
        );
        border-radius: 30px;
      }

      img {
        display: block;
        border-radius: 30px;
      }
    }

    .about-details {
      flex: 1;

      .about-details-inner {
        padding: 115px 60px 30px 30px;
        position: relative;

        img {
          position: absolute;
          left: 30px;
          top: 0;
        }

        h1 {
          color: #0f0f0f;
          font-size: 36px;
          font-weight: 500;
        }

        p {
          color: #000000;
          opacity: 0.4;
          line-height: 25px;
          margin: 0;
          font-size: 16px;
        }
      }
    }

    .about-edc-exp {
      padding: 30px;

      h3 {
        font-size: 16px;
        text-transform: uppercase;
        color: #0f0f0f;
        margin-bottom: 23px;
      }

      ul li {
        + li {
          margin-top: 32px;
        }

        .date {
          color: #bcbcbc;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 12px;
        }

        h2 {
          font-size: 18px;
          color: var(--primary_color);
          opacity: 0.9;
          margin-bottom: 7px;
        }

        .type {
          color: #bcbcbc;
          font-size: 14px;
          font-weight: 400;
          margin: 0;
        }
      }
    }
  }

  .main-aboutpage .about-area .about-contact-box {
    padding-top: 102px;
  }

  /* # Project */

  .projects-area {
    padding-top: 65px;

    .container > .section-heading {
      display: none;
    }

    .section-heading {
      justify-content: center;
      margin-bottom: 15px;
    }

    .project-item {
      margin-bottom: 24px;
      padding: 16px 16px 26px 16px;

      .project-img {
        border-radius: 30px;
        margin-bottom: 13px;

        img {
          display: block;
          border-radius: 30px;
        }
      }

      .project-info {
        p {
          color: #bcbcbc;
          font-size: 14px;
          text-transform: uppercase;
          margin-bottom: 2px;
          margin-top: 5px;
        }

        h1 {
          font-size: 22px;
          color: #0f0f0f;
          opacity: 0.9;
          font-weight: 500;
          margin: 0;
        }
      }

      .project-btn {
        opacity: 0.2;
        transition: 0.3s;
        display: inline-block;
      }

      &:hover .project-btn {
        opacity: 1;
      }
    }
  }

  /* # Project Details */

  .project-details-wrap {
    .theme-btn {
      color: #ffffff;
    }
    .project-details-img {
      height: 550px;
      position: relative;
      margin-bottom: 50px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: block;
      }
    }

    .project-infos-wrap {
      position: relative;
      gap: 100px;
      padding: 36px;

      img {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
      }

      .bg-img {
        transform: translateX(0);
      }

      .project-details-info {
        position: relative;
        padding: 24px;
      }
    }

    .project-about-2 .left-details:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0.95;
      background: #fbfbfc;
      border-radius: 30px;
    }

    .project-infos-wrap .project-details-info {
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0.95;
        background: #fbfbfc;
        border-radius: 30px;
      }

      p {
        color: #000000;
        opacity: 0.4;
        font-size: 16px;
        line-height: 1.56;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      h3 {
        color: #0f0f0f;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: -0.1px;
        margin-bottom: 13px;
      }
    }

    .project-details-2-img {
      height: 400px;
      border-radius: 30px;
    }

    .project-details-3-img img,
    .project-details-2-img img {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 30px;
    }

    .project-details-3-img {
      height: 480px;
      position: relative;
      border-radius: 30px;
    }

    .project-about-2 {
      padding: 36px;
      gap: 80px;

      .left-details {
        position: relative;
        flex: none;
        padding: 30px 54px;
        width: 290px;

        img {
          position: absolute;
          right: 40px;
          top: 0;
        }

        ul li {
          + li {
            margin-top: 27px;
          }

          p {
            color: #bcbcbc;
            font-size: 18px;
            margin-bottom: 10px;
          }

          h4 {
            color: var(--primary_color);
            opacity: 0.9;
            font-size: 18px;
            margin: 0;
          }
        }
      }

      .right-details {
        h3 {
          font-size: 18px;
          color: #0f0f0f;
          margin-bottom: 17px;
        }

        p {
          font-size: 16px;
          color: #000000;
          opacity: 0.8;
          line-height: 1.56;
          margin-bottom: 26px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .big-btn {
      margin-top: 100px;
      color: var(--primary_color);
      font-size: 44px;
      opacity: 0.9;
      display: block;
      line-height: 1.2;
      padding: 38px 57px;
      transition: 0.3s;

      &:hover {
        background: #ffffff;
        color: #0f0f0f;
      }
    }
  }

  /* # Credentials */

  .credential-area {
    padding-top: 80px;

    .gx-row {
      gap: 80px;
    }

    .credential-sidebar {
      width: 370px;
      flex: none;
      position: sticky;
      top: 15%;

      .shadow-box {
        padding: 24px 24px 34px 24px;
      }

      &::after {
        opacity: 0.25;
      }

      .img-box {
        background: linear-gradient(
          90deg,
          #3c58e3 -15%,
          #c2ebff 58%,
          #5ab5e2 97%
        );
        border-radius: 30px;
        margin-bottom: 40px;

        img {
          display: block;
          margin: auto;
          border-radius: 30px;
        }
      }

      p {
        color: #bcbcbc;
        font-size: 16px;
        margin-bottom: 34px;
      }

      h2 {
        color: #0f0f0f;
        font-weight: 500;
        font-size: 26px;
      }

      .social-links {
        gap: 10px;
        margin-bottom: 36px;

        li a {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: block;
          line-height: 55px;
          color: var(--primary_color);
          font-size: 20px;
          background: #fbfbfc;
          transition: 0.3s;

          &:hover {
            background: #ffffff;
            color: #0f0f0f;
          }
        }
      }

      .theme-btn {
        width: 100%;
      }
    }

    .credential-content .credential-about {
      padding-bottom: 110px;
    }

    .credential-edc-exp h2 {
      color: #0f0f0f;
      font-size: 18px;
      text-transform: uppercase;
    }

    .credential-content .credential-about {
      h2 {
        color: #0f0f0f;
        font-size: 18px;
        text-transform: uppercase;
        margin-bottom: 37px;
      }

      p {
        font-size: 16px;
        color: #000000;
        opacity: 0.4;
        line-height: 1.56;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .credential-experience,
    .credential-education {
      margin-bottom: 85px;
    }

    .credential-edc-exp {
      h2 {
        margin-bottom: 22px;
      }

      .credential-edc-exp-item {
        + .credential-edc-exp-item {
          margin-top: 58px;
        }

        h4 {
          color: #bcbcbc;
          opacity: 0.6;
          font-size: 16px;
          font-weight: 500;
        }

        h3 {
          font-size: 18px;
          color: var(--primary_color);
        }

        h5 {
          font-size: 14px;
          color: #bcbcbc;
          font-weight: 400;
        }

        p {
          margin: 0;
          font-size: 16px;
          color: #000000;
          opacity: 0.4;
          line-height: 1.56;
        }
      }
    }

    .skills-wrap {
      h2 {
        font-size: 18px;
        color: #0f0f0f;
        text-transform: uppercase;
        margin-bottom: 19px;
      }

      .skill-items {
        grid-template-columns: 1fr 1fr;
      }

      .skill-item {
        width: 50%;

        .percent {
          color: #bcbcbc;
          display: block;
          font-weight: 500;
          margin-bottom: 9px;
        }

        .name {
          color: var(--primary_color);
          font-size: 18px;
          margin-bottom: 4px;
        }

        p {
          color: #bcbcbc;
          font-size: 14px;
          margin: 0;
        }
      }
    }

    .awards-wrap {
      padding-top: 100px;

      &.skills-wrap {
        .skill-items {
          grid-template-columns: 1fr;
        }

        .skill-item {
          width: 100%;
        }
      }
    }
  }

  /* # Service */

  .service-area {
    padding-top: 87px;

    .container > .section-heading {
      display: none;
    }

    .service-sidebar {
      height: 100%;

      .service-sidebar-inner {
        height: 100%;
        padding: 91px 24px 90px 60px;

        ul {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-between;

          li {
            font-size: 16px;
            line-height: 16.8px;
            letter-spacing: -1%;
            font-weight: 400;
            color: #0f0f0f;
            display: flex;
            align-items: center;
            gap: 50px;

            i {
              color: var(--primary_color);
              font-size: 30px;
              display: block;
            }
          }
        }
      }
    }

    .service-content-wrap .service-content-inner {
      padding: 27px 30px;

      .service-items {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        justify-content: space-between;
      }

      .service-item {
        width: calc(50% - 12px);
        padding: 24px 47px 59px 30px;
        background: #fbfbfc;
        border-radius: 30px;

        h3 {
          color: #0f0f0f;
          font-size: 15px;
          font-weight: 500;
          text-transform: uppercase;
          letter-spacing: -1%;
        }

        p {
          color: #000000;
          opacity: 0.4;
          line-height: 25px;
          font-size: 14px;
          margin: 0;
        }
      }
    }

    .profile-contact-credentials-wrap .about-contact-box {
      height: 100%;
      padding-top: 102px;
    }
  }

  /* # Blog */

  .blog-area {
    padding-top: 130px;
  }

  .blog-sidebar .blog-sidebar-inner {
    .blog-sidebar-widget {
      margin-bottom: 40px;

      .blog-sidebar-widget-inner {
        padding: 46px 30px 62px 30px;
      }
    }

    .search-widget {
      .blog-sidebar-widget-inner {
        padding: 0;
      }

      form {
        position: relative;
        border-radius: 30px;

        &::before,
        &::after {
          border-radius: 30px;
        }

        input {
          width: 100%;
          border: none;
          background: transparent;
          padding: 19px 20px;
          border-radius: 30px;
          color: #0f0f0f;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

        .theme-btn {
          border: none;
          border-radius: 30px;
          position: absolute;
          right: 6px;
          top: 6px;
        }
      }
    }

    .categories-widget ul li,
    .recent-post-widget ul li {
      display: block;
      margin-bottom: 24px;
    }

    .categories-widget ul li:last-child,
    .recent-post-widget ul li:last-child {
      margin-bottom: 0;
    }

    .categories-widget ul li a,
    .recent-post-widget ul li a {
      display: block;
      line-height: 25px;
      font-size: 16px;
      color: #000000;
      opacity: 0.4;
      transition: 0.3s;
    }

    .categories-widget ul li a:hover,
    .recent-post-widget ul li a:hover {
      color: var(--primary_color);
      opacity: 1;
    }

    .blog-sidebar-widget h3 {
      color: #000000;
      font-size: 18px;
      text-transform: uppercase;
      margin-bottom: 30px;
    }
  }

  .blog-details-area .blog-details-content .tags,
  .blog-sidebar .blog-sidebar-inner .tags-widget ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .blog-details-area .blog-details-content .tags a,
  .blog-sidebar .blog-sidebar-inner .tags-widget ul li a {
    padding: 10px 22px;
    font-size: 14px;
    font-weight: 400;
  }

  .blog-details-area .blog-details-content .tags a:hover,
  .blog-sidebar .blog-sidebar-inner .tags-widget ul li a:hover {
    background: var(--primary_color);
    color: #ffffff;
  }

  .blog-area .blog-items {
    padding-right: 85px;

    .blog-item {
      + .blog-item {
        margin-top: 70px;
      }

      .img-box {
        height: 400px;
        position: relative;
        margin-bottom: 70px;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .blog-details-area .blog-details-content .meta,
  .blog-area .blog-items .blog-item .content .meta {
    display: block;
    color: var(--primary_color);
    font-size: 16px;
    letter-spacing: -1%;
    font-weight: 400;
    line-height: 16.8px;
    margin-bottom: 10px;
  }

  .blog-details-area .blog-details-content h1 {
    font-size: 24px;
    line-height: 26.8px;
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  .blog-area .blog-items .blog-item .content h1 {
    font-size: 24px;
    line-height: 26.8px;
    text-transform: uppercase;
    margin-bottom: 24px;

    a {
      color: #0f0f0f;
    }
  }

  .blog-details-area .blog-details-content p,
  .blog-area .blog-items .blog-item .content p {
    font-size: 16px;
    line-height: 25px;
    color: #000000;
    opacity: 0.4;
    font-weight: 400;
    margin-bottom: 30px;
  }

  .blog-details-area {
    padding-top: 130px;

    .blog-details-content {
      padding-right: 85px;

      .img-box {
        margin-bottom: 70px;

        img {
          display: block;
          width: 100%;
        }
      }

      h1 {
        color: #0f0f0f;
      }

      .list {
        margin-bottom: 30px;

        li {
          color: #898989;
          font-size: 16px;
          line-height: 25px;
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .comments-and-form-wrap {
        margin-top: 70px;

        .comments-and-form-wrap-inner {
          padding: 40px 30px 40px 30px;
        }

        h2 {
          font-size: 24px;
          line-height: 26.8px;
          color: #0f0f0f;
          letter-spacing: -1%;
          margin-bottom: 40px;
        }

        .comments {
          .comment-list {
            display: flex;
            align-items: flex-start;
            gap: 40px;
            padding-bottom: 40px;
            margin-bottom: 40px;
            border-bottom: 1px solid #bcbcbc;
          }

          .children {
            padding-left: 80px;
          }

          .comment-list {
            .comment-avatar {
              width: 100px;
              height: 100px;
              position: relative;
              flex: none;

              img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50px;
              }
            }

            .comment-body {
              .date {
                line-height: 16.8px;
                color: var(--primary_color);
                letter-spacing: -1%;
                font-size: 16px;
                font-weight: 500;
                display: block;
                margin-bottom: 10px;
              }

              h3 {
                font-size: 24px;
                line-height: 26.8px;
                color: #0f0f0f;
                letter-spacing: -1%;
              }

              p {
                color: #000000;
                opacity: 0.4;
                font-size: 16px;
                line-height: 25px;
              }

              .reply-btn {
                padding: 10px 22px;
                font-size: 14px;
              }
            }
          }
        }

        .comment-form form {
          .input-group {
            textarea,
            input {
              width: 100%;
              border: none;
              background: #fbfbfc;
              border-radius: 10px;
              padding: 17px 20px;
              font-size: 14px;
              display: block;
              color: #0f0f0f;
            }

            margin-bottom: 16px;

            &:last-child {
              margin-bottom: 0;
            }

            textarea {
              resize: none;
              height: 145px;
            }
          }

          button {
            border: none;
            width: 100%;
            padding: 18px 30px;
          }

          .input-group {
            textarea:focus,
            input:focus {
              box-shadow: none;
              outline: none;
            }
          }
        }
      }
    }
  }

  /* # Contact */

  .contact-area {
    padding-top: 80px;

    .contact-form {
      position: relative;
      max-width: 765px;
      width: 100%;

      .shadow-box {
        padding: 40px 40px 40px 40px;
      }

      img {
        position: absolute;
        top: 0;
        right: 50px;
      }

      h1 {
        font-size: 44px;
        color: #0f0f0f;
        margin-bottom: 30px;

        span {
          color: var(--primary_color);
        }
      }

      form .input-group {
        + .input-group {
          margin-top: 16px;
        }

        textarea,
        input {
          width: 100%;
          border: none;
          opacity: 0.95;
          background: #fbfbfc;
          border-radius: 10px;
          padding: 17px 20px;
          font-size: 14px;
          display: block;
          color: #0f0f0f;
        }

        textarea {
          height: 145px;
          resize: none;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

        input:focus {
          outline: none;
          box-shadow: none;
        }

        button {
          width: 100%;
          border: none;
          border-radius: 10px;
          font-size: 14px;
          padding: 16px 30px;
        }
      }
    }

    .contact-infos {
      width: 290px;
      flex: none;

      h3 {
        color: #0f0f0f;
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: 29px;
      }

      .contact-details {
        margin-bottom: 55px;

        li {
          gap: 24px;

          + li {
            margin-top: 70px;
          }

          .icon-box {
            width: 68px;
            height: 68px;
            border-radius: 10px;
            flex: none;
            text-align: center;
            line-height: 75px;
            font-size: 30px;
            color: var(--primary_color);

            &::after,
            &::before {
              border-radius: 10px;
            }
          }

          .right {
            span {
              display: block;
              color: #bcbcbc;
              font-size: 14px;
              text-transform: uppercase;
              line-height: 1.2;
              margin-bottom: 7px;
            }

            h4 {
              color: #000000;
              font-size: 16px;
              opacity: 0.4;
              font-weight: 500;
              margin-bottom: 7px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .social-links {
      gap: 20px;

      li a {
        width: 82px;
        height: 82px;
        display: block;
        text-align: center;
        line-height: 92px;
        color: var(--primary_color);
        border-radius: 50%;
        font-size: 32px;
        transition: 0.3s;

        &:hover {
          background: #ffffff;
          color: #0f0f0f;
        }

        &:before,
        &:after {
          border-radius: 50%;
        }
      }
    }
  }

  /* # Footer */

  .footer-area {
    padding-top: 128px;
    padding-bottom: 78px;
  }

  .main-workspage .footer-area {
    padding-top: 104px;
  }

  .footer-area .footer-content {
    .logo {
      display: inline-block;

      img {
        display: block;
      }
    }

    .footer-menu {
      margin: 33px 0 37px 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 44px;
      flex-wrap: wrap;

      li a {
        color: #676767;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.1px;
        font-weight: 500;
        transition: 0.3s;

        &:hover {
          color: var(--primary_color);
        }
      }
    }

    .copyright {
      margin: 0;
      color: #727272;
      font-size: 14px;
      font-weight: 500;

      span {
        color: var(--primary_color);
      }
    }
  }

  @keyframes marquee {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-65.3%);
    }
  }

  @keyframes loader_running {
    0% {
      transform: scaleX(0);
      transform-origin: left;
    }

    49% {
      transform: scaleX(1);
      transform-origin: left;
    }

    50% {
      transform: scaleX(1);
      transform-origin: right;
    }

    100% {
      transform-origin: right;
      transform: scaleX(0);
    }
  }

  @keyframes preloader_slide {
    from {
      transform: scaleY(0);
    }

    to {
      transform: scaleY(1);
    }
  }

  /* Responsive */
  @media (max-width: 1090px) {
    .container {
      padding: 0 20px;
    }

    .about-area .about-me-box {
      padding: 40px 30px;

      .img-box {
        width: 190px;
        height: 190px;
      }

      .infos {
        padding-top: 10px;

        h1 {
          font-size: 30px;
          line-height: 1.2;
        }

        .about-btn {
          bottom: 25px;
        }
      }
    }

    .info-box {
      padding: 20px;

      > img {
        width: 75%;
      }

      &.about-services-box .icon-boxes {
        margin-bottom: 39px;
        margin-top: 31px;
      }

      .inner-profile-icons {
        padding: 16px;

        a {
          width: 62px;
          height: 62px;

          i {
            font-size: 25px;
          }
        }
      }

      &.about-contact-box {
        .star-icon {
          width: auto;
        }

        padding-top: 74px;
      }
    }

    .section-heading {
      font-size: 54px;

      img {
        width: 25px;
      }
    }

    .service-area {
      .service-sidebar .service-sidebar-inner {
        padding: 50px 24px;
        height: auto;

        ul {
          justify-content: center;
          gap: 114px;
        }
      }

      .service-content-wrap .service-content-inner .service-item {
        width: 100%;
        padding-bottom: 30px;
      }
    }
  }

  @media (max-width: 920px) {
    .about-area {
      .container .row:first-child .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
      }

      .about-me-box {
        margin-bottom: 24px;

        .img-box {
          width: 224px;
          height: 224px;
        }

        .infos h1 {
          font-size: 36px;
          line-height: 1.2;
        }
      }
    }

    .info-box.about-client-box .clients .client-item {
      padding: 42px 10px 42px 10px;

      h1 {
        font-size: 26px;
        margin-bottom: 12px;
      }
    }

    .about-area .container > .row:nth-child(2) .blog-service-profile-wrap {
      /* display: grid !important; */
      /* grid-template-columns: 1fr 1fr; */
      flex-wrap: wrap;
      justify-content: space-between;

      > div {
        width: calc(50% - 12px);

        &:nth-child(2) {
          order: 3;
        }
      }
    }

    .info-box.about-services-box .icon-boxes {
      gap: 40px;
    }

    .about-area .container > .row:nth-child(3) .col-md-6 {
      width: 100%;
    }

    .info-box.about-client-box {
      margin-bottom: 24px;
    }

    .section-heading {
      font-size: 36px;
      justify-content: center;
    }

    .profile-contact-credentials-wrap {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
    }

    .project-details-wrap {
      .project-about-2,
      .project-infos-wrap {
        gap: 24px;
      }
    }

    .breadcrumb-area .breadcrumb-content .section-heading br {
      display: none;
    }
  }

  @media (max-width: 810px) {
    .credential-area {
      .credential-sidebar {
        width: 300px;
      }

      .container .gx-row {
        gap: 40px;
      }
    }

    .about-area .about-me-wrap {
      flex-direction: column;
      gap: 40px;
      align-items: center !important;
    }

    .project-details-wrap .project-infos-wrap {
      flex-direction: column;
      padding: 50px 24px 24px 24px;
    }

    .about-area .about-details .section-heading {
      margin-bottom: 40px;
    }
  }

  @media (max-width: 767px) {
    .header-area {
      padding: 20px 0;

      .navbar {
        position: fixed;
        width: 100%;
        background: #fbfbfc;
        z-index: 15;
        overflow-x: hidden;

        /* height: 80px; */
        transform-origin: 50% 50% 0px;
        top: 0;
        left: 0;
        padding-top: 100px;
        padding-bottom: 24px;
        justify-content: center;
        opacity: 0;
        visibility: hidden;

        /* max-height: 0; */
        will-change: transform;

        /* transform: translate3d(0px, -11.4272px, 0px) scale(1, 1) scale(1, 0.945615); */
        transform: translate3d(0px, -300px, 0px) scale(1, 1) scale(1, 0.945615);
        transform: translate3d(0px, -300px, 0px) scale(1, 0.5) scale(1, 0.5);
        transition: 0.5s;

        &.active {
          opacity: 1;
          visibility: visible;

          /* height: min-content; */
          transform: translate3d(0px, 0, 0px) scale(1, 1) scale(1, 1);

          /* max-height: 90vh; */
        }

        .menu {
          flex-direction: column;
          justify-content: center;
          text-align: center;
          align-items: center;
          width: 100%;
          gap: 30px;

          li a {
            padding: 0;
          }
        }

        .theme-btn {
          display: inline-block;
          margin-top: 48px;
        }
      }

      .gx-row > .theme-btn {
        display: none;
      }

      .show-menu {
        display: flex;
      }
    }

    .contact-area .gx-row,
    .credential-area .container .gx-row {
      flex-direction: column;
    }

    .contact-area .contact-form {
      margin-top: 50px;
    }

    .project-details-wrap .project-about-2 .left-details,
    .contact-area .contact-form,
    .credential-area .credential-sidebar {
      width: 100%;
    }

    .about-area .about-experience {
      margin-bottom: 24px;
    }

    .projects-area .container > .row {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .project-details-wrap .project-about-2,
    .projects-area .container > .row .col-md-8 > .d-flex {
      flex-direction: column;
    }

    .project-details-wrap .project-details-img {
      height: 300px;
    }

    .breadcrumb-area .breadcrumb-content .section-heading {
      font-size: 36px;
    }

    .service-area {
      .service-sidebar .service-sidebar-inner {
        padding: 50px 24px;
        width: 100%;
        margin: auto auto 30px auto;

        ul {
          gap: 50px;
          max-width: 220px;
          margin: auto;
        }
      }

      .col-md-8 > .section-heading {
        display: none;
      }

      .container > .section-heading {
        display: flex;
        margin-bottom: 30px;
      }
    }

    .blog-details-area,
    .blog-area {
      padding-top: 40px;
    }

    .blog-details-area .blog-details-content {
      padding-right: 0;
    }

    .blog-area .blog-items {
      padding-right: 0;

      .blog-item .img-box {
        margin-bottom: 50px;
      }
    }

    .blog-sidebar {
      margin-top: 50px;
    }
  }

  @media (max-width: 585px) {
    .about-area {
      .about-me-box {
        flex-direction: column;

        .img-box {
          margin: auto;
        }
      }

      .container > .row:nth-child(2) .blog-service-profile-wrap {
        grid-template-columns: 1fr;
      }

      .about-credentials-wrap > .gx-row {
        flex-wrap: wrap;

        > * {
          width: 100%;
          flex: none;
        }
      }
    }

    .info-box {
      > img {
        width: auto;
      }

      &.about-client-box .clients {
        /* display: grid !important;
        grid-template-columns: 1fr 1fr; */
        flex-wrap: wrap;
        justify-content: center !important;

        .client-item {
          width: calc(50% - 12px);
          flex: none;
        }
      }

      &.about-contact-box h1 {
        font-size: 30px;
        line-height: 1.2;
      }
    }

    .contact-area {
      .contact-form {
        h1 {
          font-size: 34px;
        }

        .shadow-box {
          padding: 55px 20px 30px 20px;
        }
      }

      .social-links li a {
        width: 50px;
        height: 50px;
        line-height: 58px;
        font-size: 20px;
      }

      .contact-infos .contact-details li {
        .icon-box {
          width: 58px;
          height: 58px;
          line-height: 63px;
          font-size: 25px;
        }

        + li {
          margin-top: 40px;
        }
      }
    }

    .projects-area .container > .row,
    .profile-contact-credentials-wrap {
      grid-template-columns: 1fr;
    }

    .about-area {
      .about-image-box {
        max-width: 370px;
        width: 100%;
        padding: 15px;
      }

      .about-details .about-details-inner h1 {
        font-size: 26px;
      }
    }

    .section-heading {
      font-size: 26px;
    }

    .about-area .about-details .about-details-inner {
      padding-top: 80px;
    }

    .projects-area {
      .container > .section-heading {
        display: flex;
        margin-bottom: 40px;
      }

      .col-md-8 .section-heading {
        display: none;
      }
    }

    .breadcrumb-area .breadcrumb-content .section-heading {
      font-size: 24px;
    }

    .project-details-wrap {
      .project-details-3-img,
      .project-details-2-img {
        height: 250px;
      }

      .row .col-md-6:first-child .project-details-3-img {
        margin-bottom: 24px;
      }

      .project-about-2 {
        padding: 30px;

        .left-details {
          padding: 30px;
        }
      }

      .big-btn {
        margin-top: 50px;
        font-size: 25px;
        padding: 30px 46px;
      }
    }

    .about-area .container > .row:nth-child(2) .blog-service-profile-wrap > div,
    .projects-area .project-item .project-img img {
      width: 100%;
    }

    .blog-area .blog-items .blog-item .img-box {
      height: 300px;
    }

    .blog-details-area .blog-details-content .comments-and-form-wrap .comments {
      .children {
        padding-left: 40px;
      }

      .comment-list {
        gap: 20px;

        .comment-avatar {
          width: 70px;
          height: 70px;
        }
      }
    }
  }

  @media (max-width: 425px) {
    .about-area .about-me-box .infos {
      h1 {
        font-size: 30px;
      }

      padding-right: 20px;
    }

    .info-box.about-services-box .icon-boxes {
      gap: 25px;

      i {
        font-size: 30px;
      }
    }

    .credential-area .skills-wrap .skill-items,
    .info-box.about-client-box .clients {
      grid-template-columns: 1fr;
    }

    .footer-area .footer-content .footer-menu {
      gap: 24px;
    }

    .credential-area .credential-content .credential-about {
      padding-bottom: 70px;
    }

    .contact-area .contact-form h1 {
      font-size: 30px;
    }

    .info-box.about-client-box .clients .client-item {
      width: 100%;
    }

    .blog-details-area .blog-details-content .comments-and-form-wrap .comments {
      .comment-list {
        flex-direction: column;
        align-items: center;
        text-align: center;
      }

      .children {
        padding-left: 0;
      }
    }
  }

  // Disqus
  #disqus_thread {
    color-scheme: none;
  }
}
